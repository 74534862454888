import React from 'react';
import { graphql } from 'gatsby';
import Header from '../components/Header';
import Footer from '../components/Footer';
import HeroGraphic from '../assets/HeroGraphic.svg';
import SEO from '../components/SEO';

const Jobs = ({data, ...props }) => {
	const jobs = data.allMarkdownRemark.nodes;
	return (
		<SEO {...props}>
			<Header />
			<section className="relative overflow-hidden">
				<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
					<img
						src={HeroGraphic}
						alt="Swirl Line"
						className="absolute left-52 top-0 sm:left-96 md:top-6 2xl:top-9 overflow-hidden z-0 xl:pl-96"
					/>
					<div className="relative py-24 text-xl max-w-3xl z-10">
						<h1 className="text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl z-10">Join Our Team</h1>
						<p className="z-10">
							We are a team of marketers, tech geeks, career-switchers, and insurance experts who all love a
							little adventure. We're working to make sure everyone feels confident in enjoying their passions
							without the fear of sudden financial burdens.
						</p>
						<br />
						<p>
							If you want to be part of that mission, we want to hear from you.
						</p>
						<br />
						<p className="text-sm italic">
							Buddy is an equal opportunity employer committed to diversity, equity and inclusion in the workplace.
							All qualified applicants will receive consideration for employment without regard to sex, race, color,
							age, national origin, religion, sexual orientation, gender identity, protected veteran status, disability
							or other protected status.
						</p>
					</div>
				</div>
			</section>
			<section className="gradient relative overflow-hidden">
				<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
					<div className="relative py-24 text-xl max-w-3xl z-10">
						<h1 className="text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl z-10">Career Opportunities</h1>
						<p className="z-10">
							Thank you for your interest in joining our team! See below for our current job openings.
							<br />
							{/* We're not actively hiring at the moment, but we're always on the lookout for talented people. */}
						</p>
						<br />
						<ul>
							{jobs.map((job) => (
								<li key={job.id} className="mb-4">
									<h2 className="text-2xl">
										<a href={job.fields.slug} className="text-gray-800 hover:text-gray-900 underline">
											{job.frontmatter.title}
										</a>
									</h2>
									<p className="text-gray-700">
										{job.excerpt}
										<span className="text-sm text-black">
											{' '}
											(
											<a href={job.fields.slug} className="underline">learn more</a>
											)
										</span>
									</p>
								</li>
							))}
						</ul>
						<h2 className="mt-8">Don't see the perfect job?</h2>
						<p className="z-10">
							Email
							{' '}
							<a className="text-gray-500 hover:text-gray-900" href="mailto:jobs@buddy.insure">jobs@buddy.insure</a>
							{' '}
							with your resume and an intro outlining what kind of roles you'd like &amp; how you would like to contribute to Buddy.
							We'll keep your info on file and may contact you when we have an opening that matches your qualifications.
						</p>
						<br />
						<p className="z-10">At Buddy, we want you to take your shot and apply, because you miss every shot that you don’t take!</p>
					</div>
				</div>
			</section>
	
			<Footer />
		</SEO>
	);
};

export const query = graphql`
  {
    allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/jobs/"}}) {
      nodes {
        id
        excerpt
        frontmatter {
          title
        }
        fields {
          slug
        }
      }
    }
  }
`;

export default Jobs;
